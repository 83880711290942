import { QuestionType } from 'src/api/types/exam';

export const questionTypeMap = {
  [QuestionType.SingleChoice]: '单选题',
  [QuestionType.MultipleChoice]: '多选题',
  [QuestionType.QuestionAndAnswer]: '问答题',
  [QuestionType.Judgment]: '判断题',
  [QuestionType.UnCertainMultiple]: '不定项选择',
  [QuestionType.FillInBlanks]: '填空题',
};

export const questionTypeList = [
  {
    label: questionTypeMap[QuestionType.SingleChoice],
    value: QuestionType.SingleChoice,
  },
  {
    label: questionTypeMap[QuestionType.MultipleChoice],
    value: QuestionType.MultipleChoice,
  },
  {
    label: questionTypeMap[QuestionType.QuestionAndAnswer],
    value: QuestionType.QuestionAndAnswer,
  },
  {
    label: questionTypeMap[QuestionType.Judgment],
    value: QuestionType.Judgment,
  },
  {
    label: questionTypeMap[QuestionType.UnCertainMultiple],
    value: QuestionType.UnCertainMultiple,
  },
  {
    label: questionTypeMap[QuestionType.FillInBlanks],
    value: QuestionType.FillInBlanks,
  },
];

export const questionSelect = [
  '',
  '单选题',
  '多选题',
  '问答题',
  '判断题',
  '不定项选择题',
  '填空题',
];
// 默认选择

export enum InsertType {
  top = 'top',
  bottom = 'bottom',
}

export const DragType = 'question';

export enum GradePublishMode {
  /** 不公布 */
  NoPublish = 0,
  /** 交卷后公布 */
  PublishAfterSubmit = 1,
  /** 考试结束后公布 */
  PublishAfterEnd = 2,
  /** 指定时间后公布 */
  PublishAfterTime = 3,
}

export enum SolutionPublishMode {
  /** 不公布 */
  NoPublish = 0,
  /** 和成绩一起公布 */
  PublishWithGrade = 1,
  /** 指定时间后公布 */
  PublishAfterTime = 2,
}

export const gradeTimeItems = [
  { value: GradePublishMode.PublishAfterSubmit, label: '交卷后立即公布' },
  { value: GradePublishMode.PublishAfterEnd, label: '考试结束后公布' },
  { value: GradePublishMode.PublishAfterTime, label: '指定时间公布' },
];
export const answerTimeItems = [
  { value: SolutionPublishMode.NoPublish, label: '不公布' },
  { value: SolutionPublishMode.PublishWithGrade, label: '与考试成绩一起公布' },
  { value: SolutionPublishMode.PublishAfterTime, label: '指定时间公布' },
];
export const screenMonitorTimes = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];

export const AddQuestionInBankPageSize = 8;

export const VideoIntervalMap = [
  { value: 30, label: '30秒' },
  { value: 60, label: '1分钟' },
  { value: 90, label: '1分30秒' },
  { value: 120, label: '2分钟' },
  { value: 150, label: '2分30秒' },
  { value: 180, label: '3分钟' },
  { value: 210, label: '3分30秒' },
  { value: 240, label: '4分钟' },
  { value: 270, label: '4分30秒' },
  { value: 300, label: '5分钟' },
  { value: 360, label: '6分钟' },
  { value: 420, label: '7分钟' },
  { value: 480, label: '8分钟' },
  { value: 540, label: '9分钟' },
  { value: 600, label: '10分钟' },
];
